import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const LinkTag = styled.a();

export const UnderlinedLink = styled(LinkTag)`
  display: inline;
  text-decoration: underline;

  &:hover {
    text-decoration: underline;
  }
`;

export const TextColorLink = styled(LinkTag)(
  ({ theme }) => css`
    display: inline;
    color: ${theme.colors.onBackground};

    &:hover {
      color: ${theme.colors.onBackgroundVariant};
    }
  `,
);

export const ButtonLink = styled(LinkTag)(
  ({ theme }) => css`
    display: inline-block;
    color: ${theme.colors.surfaceLight};
    &:hover {
      color: ${theme.colors.surfaceLight};
    }
  `,
);
