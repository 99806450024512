import React, { ElementType, useMemo } from 'react';

import { TextColorLink, UnderlinedLink, ButtonLink, LinkTag } from './Link.styles';

interface ILink extends Omit<React.HTMLProps<HTMLAnchorElement>, 'as'> {
  href: string;
  className?: string;
  onClick?: () => void;
  as?: ElementType;
  underlined?: boolean;
  textColor?: boolean;
  button?: boolean;
}

const getStyleByProps = (underlined?: boolean, textColor?: boolean, button?: boolean): ElementType | undefined => {
  if (underlined) {
    return UnderlinedLink;
  }

  if (textColor) {
    return TextColorLink;
  }

  if (button) {
    return ButtonLink;
  }

  return;
};

export const ExternalLink: React.FC<ILink> = ({
  href,
  className,
  onClick,
  children,
  as,
  underlined,
  textColor,
  button,
  ...anchorProps
}) => {
  const styleElement = useMemo(() => getStyleByProps(underlined, textColor, button), [underlined, textColor, button]);

  return (
    <LinkTag {...anchorProps} href={href} className={className} onClick={onClick} as={as || styleElement}>
      {children}
    </LinkTag>
  );
};
