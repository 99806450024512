import { GetStaticProps } from 'next';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';

import { Language } from 'enums/language';

import { ExternalLink } from 'library/components/Link';
import { ErrorWrapper } from 'library/layouts/ErrorWrapper';

export default function Custom404(): JSX.Element {
  return (
    <ErrorWrapper
      type="404"
      onButtonClick={() => (location.href = '/')}
      logoLink={{
        href: '/',
        as: ExternalLink,
      }}
      bottomLink={{
        href: '/',
        as: ExternalLink,
      }}
    />
  );
}

export const getStaticProps: GetStaticProps = async context => {
  const { locale, defaultLocale } = context;
  const staticLocale = (locale as Language) || (defaultLocale as Language);
  return {
    props: await serverSideTranslations(`${staticLocale}`, ['common', 'errors']),
  };
};
