import { ErrorPage } from '@rainhq/ui-library';
import { useTranslation } from 'next-i18next';

import { IErrorWrapperProps } from './types';

export const ErrorWrapper: React.FC<IErrorWrapperProps> = ({
  type,
  onButtonClick,
  bottomLink = { href: '/' },
  logoLink = { href: '/' },
}) => {
  const { t } = useTranslation('errors');

  return (
    <ErrorPage
      type={type}
      onButtonClick={() => onButtonClick()}
      logoLink={logoLink}
      title={t(`error-page.title.${type}`)}
      description={t(`error-page.description.${type}`)}
      buttonText={t(`error-page.buttonText.${type}`)}
      bottomLink={{
        ...bottomLink,
        text: t(`error-page.linkText.${type}`),
      }}
    />
  );
};
